import { ChapterModel } from '../models'
import { LOCALIZATION } from '../config/constants/localStorage'

export const getFont = (
  font: 'font-lato' | 'font-patua',
  correction?: string
): string => {
  if (typeof window !== 'undefined') {
    if (localStorage.getItem(LOCALIZATION) === 'ADN') {
      return 'font-adina'
    }
    if (font === 'font-lato') {
      return `font-lato${correction ? ` ${correction}` : ''}`
    }
  }
  return font
}

export const currentYear = (): number => {
  return new Date().getFullYear()
}

export const clone = (object: any): any => {
  return JSON.parse(JSON.stringify(object))
}

export const createPath = (text: string): string => {
  return text.toLowerCase().replace(/\s/g, '-')
}

export const romanizeAdn = (adn: string) =>
  adn.replace(/\|/g, 'gl').replace(/¿/, 'ch').replace(/¡/g, 'sh')

export const capitalizeFirst = (text: string) =>
  `${text.substring(0, 1).toUpperCase()}${text.substring(1).toLowerCase()}`

export const compareByNumber = (a: ChapterModel, b: ChapterModel) => {
  return a.number - b.number
}

export const comparebySeries = (a: ChapterModel, b: ChapterModel) => {
  return a.series.id - b.series.id
}

export const comparebyPublishDate = (a: ChapterModel, b: ChapterModel) => {
  if (a.publicationDate > b.publicationDate) {
    return -1
  }
  if (a.publicationDate < b.publicationDate) {
    return 1
  }
  return 0
}
