const end = {
  Default: {
    title: 'lbl_title',
    description: 'lbl_description',
    link: 'lbl_link',
    next: 'lbl_title',
    chapter: 'lbl_chapter',
    series: 'lbl_series',
    follow_us: 'lbl_support',
    discord: 'lbl_discord',
  },
  ENG: {
    title: "Oops... that's all for today",
    description: 'But do not panic, you can enjoy more chapters if you ',
    link: 'support us',
    next: 'To satiate your curiosity here is a small description of ',
    chapter: 'Chapter',
    series: 'Read another series',
    follow_us: 'Follow us on our socials',
    discord: 'Join our Discord',
  },
  ESP: {
    title: 'Ups... eso es todo por hoy',
    description: 'Pero no te preocupes, puedes disfrutar de más capítulos si ',
    link: 'nos apoyas',
    next: 'Para saciar tu curiosidad, aquí hay una pequeña descripción del ',
    chapter: 'Capítulo',
    series: 'Ver otra serie',
    follow_us: 'Síguenos en redes sociales',
    discord: 'Únete a nuestro Discord',
  },
  ADN: {
    title: 'lbl_title',
    description: 'lbl_description',
    link: 'lbl_link',
    next: 'lbl_title',
    chapter: 'lbl_chapter',
    series: 'lbl_series',
    follow_us: 'lbl_support',
    discord: 'lbl_discord',
  },
}

export { end }
