import { common } from './auth/common'

const account = {
  Default: {
    hello_title: 'hello_title',
    user_since: 'user_since',
    comment_num: 'comment_num',
    keep_reading: 'keep_reading',
    current_chapter: 'current_chapter',
    title: 'change_title',
    password: 'pass_label',
    passwordNew: 'new_pass',
    passwordConfirm: 'config_pass',
    change: 'change_cta',
    min_password: 'min_password',
    confirm_invalid: 'confirm_invalid',
    success_change: 'success_change',
    invalid_pass: 'invalid_pass',
    benefits: 'extra_benefits',
    support_us: 'support_us',
    select_image: 'select_image',
    ...common.Default,
  },
  ENG: {
    hello_title: 'Hello',
    user_since: 'User since:',
    comment_num: 'Comments:',
    keep_reading: 'Keep Reading',
    current_chapter: 'Current Chapter',
    title: 'Change your password',
    password: 'Current password',
    passwordNew: 'New Password',
    passwordConfirm: 'Confirm password',
    change: 'Change password',
    min_password: 'Your password must have at least 6 characters',
    confirm_invalid: 'Passwords do not match',
    success_change: 'Password sucessfully changed',
    invalid_pass: 'Incorrect password',
    benefits: 'Tier rewards',
    support_us: 'Unlock rewards',
    select_image: 'Select your image',
    ...common.ENG,
  },
  ESP: {
    hello_title: 'Hola',
    user_since: 'Usuario desde:',
    comment_num: 'Comentarios:',
    keep_reading: 'Seguir Leyendo',
    current_chapter: 'Capitulo actual',
    title: 'Cambia tu contraseña',
    password: 'Contraseña actual',
    passwordNew: 'Nueva contraseña',
    passwordConfirm: 'Confirmar contraseña',
    change: 'Cambiar contraseña',
    min_password: 'La contraseña debe tener más de 6 caracteres',
    confirm_invalid: 'Las contraseñas no coinciden',
    success_change: 'Cambio de contraseña exitoso',
    invalid_pass: 'Contraseña incorrecta',
    benefits: 'Recompensas extra',
    support_us: 'Obten recompensas',
    select_image: 'Selecciona tu imagen',
    ...common.ESP,
  },
  ADN: {
    hello_title: 'hello_title',
    user_since: 'user_since',
    comment_num: 'comment_num',
    keep_reading: 'keep_reading',
    current_chapter: 'current_chapter',
    title: 'change_title',
    password: 'pass_label',
    passwordNew: 'new_pass',
    passwordConfirm: 'config_pass',
    change: 'change_cta',
    min_password: 'min_password',
    confirm_invalid: 'confirm_invalid',
    success_change: 'success_change',
    invalid_pass: 'invalid_pass',
    benefits: 'extra_benefits',
    support_us: 'support_us',
    select_image: 'select_image',
    ...common.ADN,
  },
}

export { account }
