const town = {
    Default: {
      related: 'related_label',
    },
    ENG: {
        related: 'Related',
    },
    ESP: {
        related: 'Relacionadas',
    },
    ADN: {
        related: 'Sakarkiri',
    },
  }
  
  export { town }
  