const header = {
  Default: {
    comic: 'comic_nav',
    world: 'world_nav',
    aboutUs: 'about_nav',
    logIn: 'login_nav',
    myAccount: 'my_account',
    keepReading: 'keep_reading',
    logOut: 'logout_nav',
  },
  ENG: {
    comic: 'Comic',
    world: 'World',
    aboutUs: 'About Us',
    logIn: 'Login',
    myAccount: 'My Account',
    keepReading: 'Keep Reading',
    logOut: 'Logout',
  },
  ESP: {
    comic: 'Comic',
    world: 'Mundo',
    aboutUs: 'Nosotros',
    logIn: 'Iniciar Sesión',
    myAccount: 'Mi Cuenta',
    keepReading: 'Seguir Leyendo',
    logOut: 'Cerrar Sesión',
  },
  ADN: {
    comic: 'Imwa',
    world: 'Keran',
    aboutUs: 'Ensu Onam',
    logIn: 'Kein Fasa',
    myAccount: 'my_account',
    keepReading: 'keep_reading',
    logOut: 'logout_nav',
  },
}

export { header }
