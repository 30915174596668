const common = {
  Default: {
    required: 'required_field',
    password_format: 'password_format',
    username_format: 'username_format',
    unexpected_error: 'unexpected_error',
  },
  ENG: {
    required: 'Required field',
    password_format:
      'The provided password has an invalid character. Only alphanumeric characters and _#$%.,@- are allowed',
    username_format:
      'The provided username has an invalid character. Only alphanumeric characters and _- are allowed',
    unexpected_error: 'Unexpected error. Please try again',
  },
  ESP: {
    required: 'Campo requerido',
    password_format:
      'La contraseña contiene un caracter no permitido. Solo se permiten caracteres alfanuméricos y _#$%.,@-',
    username_format:
      'El nombre de usuario contiene un caracter no permitido. Solo se permiten caracteres alfanuméricos y _-',
    unexpected_error: 'Error inesperado. Por favor intente nuevamente.',
  },
  ADN: {
    required: 'required_field',
    password_format: 'password_format',
    username_format: 'username_format',
    unexpected_error: 'unexpected_error',
  },
}

export { common }
