import Axios from 'axios'

const { ENDPOINT } = process.env

interface RegisterFormData {
  username: string
  email: string
  password: string
  confirmPassword: string
  token: string
}

interface LoginFormData {
  username: string
  password: string
}

interface ChangePasswordData {
  username: string
  password: string
  newPassword: string
  confirmPassword: string
  token: string
}

interface LoginResponse {
  token: string
  user: {
    username: string
    image: number
    lastChapter: number
    lastPage: number
    since: string
    tier?: number
    images: number[]
  }
}

interface LogoutResponse {
  success: true
}

interface ValidateResponse {
  username: string
  image: number
  lastChapter: number
  lastPage: number
  since: string
  tier?: number
  images: number[]
}

export const availableUsername = (username: string): Promise<boolean> =>
  Axios.get(`${ENDPOINT}/auth/availableUsername?username=${username}`).then(
    (res) => res.data.result
  )

export const availableEmail = (email: string): Promise<boolean> =>
  Axios.get(`${ENDPOINT}/auth/availableEmail?email=${email}`).then(
    (res) => res.data.result
  )

export const registerUser = (
  registerForm: RegisterFormData
): Promise<boolean> =>
  Axios.post(`${ENDPOINT}/auth/register`, registerForm).then(
    (res) => res.data.result
  )

export const login = (loginForm: LoginFormData): Promise<LoginResponse> =>
  Axios.post(`${ENDPOINT}/auth/login`, loginForm).then((res) => res.data.result)

export const logout = (token: string): Promise<LogoutResponse> =>
  Axios.get(`${ENDPOINT}/auth/logout`, { headers: { token } }).then(
    (res) => res.data.result
  )

export const validateSession = (token: string): Promise<ValidateResponse> =>
  Axios.get(`${ENDPOINT}/auth/session`, { headers: { token } }).then(
    (res) => res.data.result
  )

export const changePassword = (
  token: string,
  changePassword: ChangePasswordData
): Promise<LoginResponse> =>
  Axios.put(`${ENDPOINT}/auth/password`, changePassword, {
    headers: { token },
  }).then((res) => res.data.result)

export const forgotPassword = (
  email: string,
  lang: string
): Promise<LoginResponse> =>
  Axios.post(`${ENDPOINT}/auth/forgot`, { email, lang }).then(
    (res) => res.data.result
  )

export const updateImage = (
  img: number,
  token: string
): Promise<LoginResponse> =>
  Axios.put(`${ENDPOINT}/auth/image`, { img }, { headers: { token } }).then(
    (res) => res.data.result
  )
