const footer = {
  Default: {
    title: 'footer_title',
    followUs: 'footer_followUs',
    supportUs: 'footer_supportUs',
  },
  ENG: {
    title: 'Want to know more?',
    followUs: 'Follow us',
    supportUs: 'Support Dilan on',
  },
  ESP: {
    title: '¿Quieres saber más?',
    followUs: 'Síguenos',
    supportUs: 'Apoya a Dilan en',
  },
  ADN: {
    title: 'Kari¡at rakupein plor',
    followUs: 'Wa¡in onas',
    supportUs: 'Kikera a Dilan ne',
  },
}

export { footer }
