import { login } from './login'
import { forgot } from './forgot'
import { register } from './register'
import { common } from './common'

const auth = {
  Default: {
    login: login.Default,
    register: register.Default,
    forgot: forgot.Default,
    common: common.Default,
  },
  ENG: {
    login: login.ENG,
    register: register.ENG,
    forgot: forgot.ENG,
    common: common.ENG,
  },
  ESP: {
    login: login.ESP,
    register: register.ESP,
    forgot: forgot.ESP,
    common: common.ESP,
  },
  ADN: {
    login: login.ADN,
    register: register.ADN,
    forgot: forgot.ADN,
    common: common.ESP,
  },
}

export { auth }
