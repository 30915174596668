const story = {
    Default: {
      read: 'read_label',
      learn_more: 'learn_more'
    },
    ENG: {
      read: 'Read',
      learn_more: 'Learn more'
    },
    ESP: {
      read: 'Leer',
      learn_more: 'Ver más'
    },
    ADN: {
      read: 'Zafin',
      learn_more: 'Kirin plor'
    },
  }
  
  export { story }
  