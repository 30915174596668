const login = {
  Default: {
    welcome: 'welcome_title',
    login_cta: 'login_cta',
    username: 'username_label',
    password: 'password_label',
    forgot_password: 'forgot_password',
    login: 'login_but',
    register: 'register_but',
    invalid_pass: 'invalid_pass',
  },
  ENG: {
    welcome: 'Welcome!',
    login_cta: 'Log in to obtain a personalized experience',
    username: 'Username',
    password: 'Password',
    forgot_password: 'Forgot your password?',
    login: 'Log in',
    register: 'Register',
    invalid_pass: 'Incorrect password or username',
  },
  ESP: {
    welcome: '¡Bienvenido!',
    login_cta: 'Inicia sesión para obtener una experiencia personalizada',
    username: 'Nombre de Usuario',
    password: 'Contraseña',
    forgot_password: '¿Olvidaste tu contraseña?',
    login: 'Iniciar Sesión',
    register: 'Registrarse',
    invalid_pass: 'Contraseña y/o usuario incorrecto',
  },
  ADN: {
    welcome: 'welcome_title',
    login_cta: 'login_cta',
    username: 'username_label',
    password: 'password_label',
    forgot_password: 'forgot_password',
    login: 'login_but',
    register: 'register_but',
    invalid_pass: 'invalid_pass',
  },
}

export { login }
