const comic = {
  Default: {
    chapter: 'lbl_chapter',
  },
  ENG: {
    chapter: 'chapters',
  },
  ESP: {
    chapter: 'capítulos',
  },
  ADN: {
    chapter: 'Zagexi',
  },
}

export { comic }
