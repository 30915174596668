const forgot = {
  Default: {
    welcome: 'welcome_title',
    forgot_cta: 'forgot_cta',
    email: 'email_label',
    send: 'send_but',
    forgot_password: 'forgot_password',
    forgot_prompt: 'forgot_prompt',
    email_invalid: 'email_invalid',
  },
  ENG: {
    welcome: 'Welcome!',
    forgot_cta:
      'If your email is linked to an account you will recieve a new password',
    email: 'Email',
    send: 'Send',
    forgot_password: 'Forgot your password?',
    forgot_prompt:
      "If your email is linked to an account we've sent a message to your email. If you can't find it in your inbox, search in your Spam folder",
    email_invalid: 'Invalid email',
  },
  ESP: {
    welcome: '¡Bienvenido!',
    forgot_cta:
      'Si su correo esta asociado a una cuenta recibiras un correo con una nueva contraseña',
    email: 'Email',
    send: 'Enviar',
    forgot_password: '¿Olvidaste tu contraseña?',
    forgot_prompt:
      'Si su correo esta asociado a una cuenta hemos enviado un mensaje a su email. Si no lo encuentra, busque en su carpeta de Spam',
    email_invalid: 'Email no valido',
  },
  ADN: {
    welcome: 'welcome_title',
    forgot_cta: 'forgot_cta',
    email: 'email_label',
    send: 'send_but',
    forgot_password: 'forgot_password',
    forgot_prompt: 'forgot_prompt',
    email_invalid: 'email_invalid',
  },
}

export { forgot }
