const bios = {
  Default: {
    related: 'related_label',
    birthday: 'birthday_label',
    species: 'species_label',
    weight: 'weight_label',
    height: 'height_label',
    food: 'food_label',
    town: 'town_label',
    color: 'color_label',
    description: 'description_label',
    chapter: 'chapter_label',
  },
  ENG: {
    related: 'Related',
    birthday: 'Birthday',
    species: 'Species',
    weight: 'Weight',
    height: 'Height',
    food: 'Favorite Food',
    town: 'Town',
    color: 'Color',
    description: 'Description',
    chapter: 'First Appearance',
  },
  ESP: {
    related: 'Relacionados',
    birthday: 'Cumpleaños',
    species: 'Especie',
    weight: 'Peso',
    height: 'Altura',
    food: 'Comida Favorita',
    town: 'Pueblo',
    color: 'Color',
    description: 'Descripción',
    chapter: 'Primera Aparición',
  },
  ADN: {
    related: 'Sakarkiri',
    birthday: 'Vonseiliam',
    species: 'Qil',
    weight: 'Wa|li',
    height: 'Tra|li',
    food: 'Ki¡ik Wa¡ek',
    town: 'Vialus',
    color: 'Magu',
    description: 'Culiriuk',
    chapter: 'Io Zagex',
  },
}

export { bios }
