const about = {
  Default: {
    title: 'title_label',
    description: 'description_label',
    daniel: 'daniel_titles',
    sebastian: 'sebastian_titles',
    oscar: 'oscar_titles',
    info: 'info_label',
    team: 'team_label',
  },
  ENG: {
    title: 'About us',
    description:
      ' We are a group of friends who got together around an old project. It all began back in school when Daniel had the idea to write and draw a manga style comic and show it to his friends. With time he made over 50 issues of Dilan and when he went to university he continued drawing and showing it to his friends in electronic engineering. One of those friends, Oscar, liked his comic and, seeing as Daniel had some orthography problems, offered to edit and help rewrite the comic. Soon they went on to plan the whole story to come, however, classes always got in the way, so progress was slow. Around that time Sebastian, an old school friend of Daniel who used to sit behind him in class and also went to the same university, offered to ink his drawings, first by hand and then digitally. With time he took charge of adding screentones and the dialog to the comic.\r\nOne day we decided we wanted to get serious with the comic and publish it online, so what you see here is the product of our efforts, we hope you enjoy it.',
    daniel: 'Artist,Engineer,Supreme Leader',
    sebastian: 'Letterer,Designer,Strategist',
    oscar: 'Writer,Engineer,Editor',
    info: 'Need more information?',
    team: 'The team',
  },
  ESP: {
    title: 'Nosotros',
    description:
      ' Somos un grupo de amigos que se unieron en torno a un viejo proyecto. Todo comenzó en el colegio cuando Daniel tuvo la idea de escribir y dibujar un comic al estilo del manga y mostrárselo a sus amigos. Con el tiempo dibujo más de 50 capítulos de Dilan y cuando entro a la universidad continuó dibujando y mostrándoselo a sus amigos en ingeniería electrónica. A uno de esos amigos, Oscar, le gusto el comic y, viendo que Daniel tenía problemas ortográficos, se ofreció a editar y reescribir el comic. Luego planearon toda la historia que seguiría el comic, sin embargo las clases consumían tiempo y el ritmo de trabajo era lento. En ese tiempo Sebastián, un viejo amigo del colegio de Daniel que solía sentarse detrás de él en clase y que entro a la misma universidad, se ofreció a hacer las tintas, primero a mano y después digitalmente. Con el tiempo también se hizo cargo de agregar tramas y dialogo al comic.\r\nUn día decidimos ponernos serios con este comic y publicarlo online, lo que ven acá es producto de nuestro esfuerzo, esperamos que lo disfruten.',
    daniel: 'Artista,Ingeniero,Lider Supremo',
    sebastian: 'Letras,Diseñador,Estratega',
    oscar: 'Escritor,Ingeniero,Editor',
    info: '¿Necesitas más información?',
    team: 'El equipo',
  },
  ADN: {
    title: 'title_label',
    description: 'description_label',
    daniel: 'daniel_titles',
    sebastian: 'sebastian_titles',
    oscar: 'oscar_titles',
    info: 'info_label',
    team: 'team_label',
  },
}

export { about }
