const support = {
  Default: {
    vaki: 'support_vaki',
    patreon: 'support_patreon',
  },
  ENG: {
    vaki: 'Become a Vaker',
    patreon: 'Become a Patreon',
  },
  ESP: {
    vaki: 'Apóyanos en Vaki',
    patreon: 'Apóyanos en Patreon',
  },
  ADN: {
    vaki: 'support_vaki',
    patreon: 'support_patreon',
  },
}

export { support }
