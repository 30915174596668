const chapter = {
  Default: {
    donate: 'comic_donate',
    page: 'com_page',
    comments: 'com_comments',
    send: 'send_comment',
    default: 'comic_default_comment',
    pageMode: 'mode_page',
    listMode: 'mode_list',
    previous: 'prev_chapter',
    next: 'next_chapter',
  },
  ENG: {
    donate: 'Donate',
    page: 'Page',
    comments: 'Comments',
    send: 'Send',
    default: 'Leave a comment',
    pageMode: 'Page mode',
    listMode: 'List mode',
    previous: 'Previous Chapter',
    next: 'Next Chapter',
  },
  ESP: {
    donate: 'Donar',
    page: 'Página',
    comments: 'Comentarios',
    send: 'Enviar',
    default: 'Deja tu commentario',
    pageMode: 'Modo página',
    listMode: 'Modo lista',
    previous: 'Capítulo Anterior',
    next: 'Siguiente Capítulo',
  },
  ADN: {
    donate: 'Gurin',
    page: 'Coda',
    comments: 'Kajili',
    send: 'Enugin',
    default: 'Zazaira uk kajil',
    pageMode: 'Coda muku',
    listMode: 'Jali muku',
    previous: 'Naer Zagex',
    next: 'Anter Zagex',
  },
}

export { chapter }
