import React, { useEffect, useState } from 'react'

// Type
import { Buttontypes } from '../../../types'

// Styles
import {
  buttonCommons,
  buttonPrimary,
  buttonSecondary,
  buttonTernary,
  buttonGhost,
  buttonGhostSecondary,
  buttonSimple,
} from './styles.module.scss'

interface Props {
  type: Buttontypes
  className: string
  handleClick: () => void
  disabled?: boolean
  children: React.ReactNode
}

const DilanButton: React.FC<Props> = ({
  type,
  handleClick,
  className,
  disabled = false,
  children,
  ...attr
}) => {
  const [buttonStyle, setButtonStyle] = useState(buttonPrimary)

  useEffect(() => {
    switch (type) {
      case 'secondary':
        setButtonStyle(buttonSecondary)
        break
      case 'ternary':
        setButtonStyle(buttonTernary)
        break
      case 'ghost':
        setButtonStyle(buttonGhost)
        break
      case 'ghost-secondary':
        setButtonStyle(buttonGhostSecondary)
        break
      case 'simple':
        setButtonStyle(buttonSimple)
        break

      default:
        setButtonStyle(buttonPrimary)
        break
    }
  }, [type])

  return (
    <button
      className={`${buttonCommons} ${buttonStyle} ${className}`}
      {...attr}
      onClick={handleClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default DilanButton
