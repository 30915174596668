const home = {
  Default: {
    comic: 'comic_title',
    comicSlide: 'com_slide',
    comicNew: 'com_new',
    comicSpin: 'comic_spin',
    comicButton: 'com_but',
    patreon: 'patreon_title',
    world: 'world_title',
    world2: 'world_title_2',
    worldSub: 'world_subtitle',
    worldButton: 'wor_but',
    character: 'character_title',
    characterButton: 'char_but',
  },
  ENG: {
    comic: 'A story on another level',
    comicSlide: 'Read',
    comicNew: 'NEW',
    comicSpin: 'Spin-Off',
    comicButton: 'All Chapters',
    patreon: 'Exclusive content',
    world: 'New World',
    world2: 'New Language',
    worldSub:
      'Explore the world of Dilan: the Chronicles of Covak, regions, cities, forests, seas and more.',
    worldButton: 'Explore',
    character: 'Some of our characters',
    characterButton: 'Characters',
  },
  ESP: {
    comic: 'Una historia a otro nivel',
    comicSlide: 'Leer',
    comicNew: 'NUEVO',
    comicSpin: 'Spin-Off',
    comicButton: 'Capítulos',
    patreon: 'Contenido exclusivo',
    world: 'Nuevo mundo',
    world2: 'Nueva lengua',
    worldSub:
      'Explora el mundo de Dilan: las Crónicas de Covak, regiones, ciudades, bosques, mares y más.',
    worldButton: 'Explora',
    character: 'Algunos personajes',
    characterButton: 'Personajes',
  },
  ADN: {
    comic: 'Uk olanuin ne zame zaze',
    comicSlide: 'Zafin',
    comicNew: 'MAYUN',
    comicSpin: 'ZAWI',
    comicButton: 'Imwai',
    patreon: 'Kagur exejut',
    world: 'Mayun keran',
    world2: 'Mayun kikirur',
    worldSub:
      'Taxira u keran ej Dilanur: Covakur raguli, xiximai, xikarumi, forahi, lisi am plor',
    worldButton: 'Taxira',
    character: 'Jukan ej onas ¿i|uk',
    characterButton: '¿i|uki',
  },
}

export { home }
