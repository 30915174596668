const register = {
  Default: {
    welcome: 'welcome_title',
    register_cta: 'register_cta',
    username: 'username_label',
    email: 'email_label',
    password: 'password_label',
    passwordConfirm: 'password_config_label',
    create: 'create_but',
    register_legal: 'register_legal',
    terms: 'terms_and_conditions',
    privacy: 'privacy_policy',
    confirm_invalid: 'confirm_invalid',
    email_invalid: 'email_invalid',
    min_username: 'min_username',
    max_username: 'max_username',
    unavailable_username: 'unavailable_username',
    unavailable_email: 'unavailable_email',
    min_password: 'min_password',
  },
  ENG: {
    welcome: 'Welcome!',
    register_cta: 'Sign up to obtain a personalized experience',
    username: 'Username',
    email: 'Email',
    password: 'Password',
    passwordConfirm: 'Confirm Password',
    create: 'Create',
    register_legal: 'By creating an account you accept our:',
    terms: 'Terms and Conditions',
    privacy: 'Privacy Policies',
    confirm_invalid: 'Passwords do not match',
    email_invalid: 'Invalid email',
    min_username: 'Your username must be at least 3 charactes long',
    max_username: "Your username can't have more than 50 charactes",
    unavailable_username: 'Username unavailable',
    unavailable_email: 'Email already registered',
    min_password: 'Your password must have at least 6 characters',
  },
  ESP: {
    welcome: '¡Bienvenido!',
    register_cta: 'Registrate para obtener una experiencia personalizada',
    username: 'Nombre de Usuario',
    email: 'Email',
    password: 'Contraseña',
    passwordConfirm: 'Confirmar Contraseña',
    create: 'Crear',
    register_legal: 'Al crear una cuenta acepta nuestros:',
    terms: 'Terminos y Condiciones',
    privacy: 'Politica de Privacidad',
    confirm_invalid: 'Las contraseñas no coinciden',
    email_invalid: 'Email no valido',
    min_username: 'El nombre de usuario debe tener almenos 3 caracteres',
    max_username: 'El nombre de usuario no puede tener más de 50 caracteres',
    unavailable_username: 'Este nombre de usuario no esta disponible',
    unavailable_email: 'Este email ya tiene una cuenta asociada',
    min_password: 'La contraseña debe tener más de 6 caracteres',
  },
  ADN: {
    welcome: 'welcome_title',
    register_cta: 'register_cta',
    username: 'username_label',
    email: 'email_label',
    password: 'password_label',
    passwordConfirm: 'password_config_label',
    create: 'create_but',
    register_legal: 'register_legal',
    terms: 'terms_and_conditions',
    privacy: 'privacy_policy',
    confirm_invalid: 'confirm_invalid',
    email_invalid: 'email_invalid',
    min_username: 'min_username',
    max_username: 'max_username',
    unavailable_username: 'unavailable_username',
    unavailable_email: 'unavailable_email',
    min_password: 'min_password',
  },
}

export { register }
