const series = {
  Default: {
    first: 'first_label',
    last: 'last_label',
  },
  ENG: {
    first: 'First Chapter',
    last: 'Last Chapter',
  },
  ESP: {
    first: 'Primer Capítulo',
    last: 'Último Capítulo',
  },
  ADN: {
    first: 'Iomu Zagex',
    last: 'Nemu Zagex',
  },
}

export { series }
